import { Box, Center, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type Props = {
  center?: boolean;
  width?: string;
  height?: string;
  to?: string;
};
const Brand = ({ center = true, width, height, to }: Props) => {
  if (center)
    return (
      <Center w="full" as={to ? Link : undefined} to={to}>
        <Image src="/logo.png" maxW="175px" />
      </Center>
    );

  return (
    <Box w={width} h={height} as={to ? Link : undefined} to={to}>
      <Image src="/logo.png" h={height} w={width} maxW="175px" />
    </Box>
  );
};

export default Brand;
