import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import WithVerification from "utils/auth/with-verification";

const Home = lazy(() => import(/*webpackChunkName: "home" */ "../views/Home"));
const Users = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/users")
);
const Departments = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/departments")
);
const CreateUser = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/users/create")
);
const CreateUserBatch = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/users/create-batch")
);
const UpdateUserBatch = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/users/update-batch")
);
const UploadSalaryPage = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/users/upload-salary")
);
const UserReview = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/users/review")
);
const Tasks = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/tasks")
);
const SmsOptions = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/settings/sms")
);
const EditSmsOption = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/settings/sms/edit")
);
const EditWorkHourRules = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/work-hour-rules/edit")
);
const CreateWorkHourRules = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/work-hour-rules/create")
);
const WorkHourRules = lazy(
  () => import(/*webpackChunkName: "home" */ "../views/work-hour-rules")
);

type Props = FeatureRoute & {};
const HomeRoutes = (props: Props) => {
  return (
    <Routes>
      <Route
        element={
          <WithVerification>
            <Outlet />
          </WithVerification>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="tasks" element={<Tasks />} />

        {props.isAdmin && (
          <>
            <Route path="users/review/:userId" element={<UserReview />} />
            <Route path="users/create" element={<CreateUser />} />
            <Route path="users/create-batch" element={<CreateUserBatch />} />
            <Route path="users/update-batch" element={<UpdateUserBatch />} />
            <Route path="users/upload-salary" element={<UploadSalaryPage />} />
            <Route path="users" element={<Users />} />
            <Route path="departments" element={<Departments />} />
            <Route path="setting/sms" element={<SmsOptions />} />
            <Route path="setting/sms/edit/:id" element={<EditSmsOption />} />
            <Route
              path="work-hour-rule/edit/:id"
              element={<EditWorkHourRules />}
            />
            <Route
              path="work-hour-rule/create"
              element={<CreateWorkHourRules />}
            />
            <Route path="work-hour-rule" element={<WorkHourRules />} />
          </>
        )}
      </Route>
    </Routes>
  );
};

export default HomeRoutes;
