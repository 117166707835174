import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SaasProvider } from "@saas-ui/react";

import theme from "./theme/theme";
import { QueryProvider } from "providers/QueryProvider";
import { AppRoutes } from "routes";
import FormProvider from "providers/FormProvider";
import Fallback from "components/fallback/Fallback";

import "features/notification/lib/firebaseSetup";
import "./i18n";
import "vazirmatn/misc/UI/Vazirmatn-UI-font-face.css";
import "./assets/css/App.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <SaasProvider theme={theme}>
    <QueryProvider>
      <FormProvider>
        <React.StrictMode>
          <BrowserRouter>
            <Suspense fallback={<Fallback />}>
              <AppRoutes />
            </Suspense>
          </BrowserRouter>
        </React.StrictMode>
      </FormProvider>
    </QueryProvider>
  </SaasProvider>
);
