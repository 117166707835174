import { forwardRef } from "react";
import BaseButton, { BaseButtonProps } from "./BaseButton";

const OutlineButton = forwardRef<
  HTMLButtonElement,
  Omit<BaseButtonProps, "variant">
>((props, ref) => {
  return <BaseButton {...props} variant="outline" ref={ref} />;
});

export default OutlineButton;
