import useStatus from "features/user/queries/useStatus";
import useAuth from "hooks/useAuth";
import usePushNotification from "features/notification/hooks/usePushNotification";
import { useRoutes } from "react-router-dom";

import { allRoutes } from "./all";

export const AppRoutes = () => {
  const { isLoggedIn, verified, roles, isAdmin, canReview } = useAuth();
  useStatus();
  usePushNotification();

  const element = useRoutes(
    allRoutes({ isLoggedIn, verified, roles, isAdmin, canReview })
  );

  return <>{element}</>;
};
