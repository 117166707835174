import { createStandaloneToast } from "@chakra-ui/react";
import theme from "theme/theme";

const { toast } = createStandaloneToast({
  theme: { ...theme },
  defaultOptions: {
    isClosable: true,
    duration: 4500,
  },
});

type ToastOptions = Partial<{
  id: string;
}>;

const appToast = {
  error: (message: string, title: string = "خطا", options?: ToastOptions) => {
    const id = options?.id ?? message;

    if (!toast.isActive(id))
      toast({
        id: id,
        title: title,
        description: message,
        status: "error",
      });
  },
  warn: (message: string, title: string = "اخطار", options?: ToastOptions) =>
    toast({
      id: message,
      title: title,
      description: message,
      status: "warning",
    }),
  success: (message: string, title?: string, options?: ToastOptions) =>
    toast({
      id: message,
      title: title,
      description: message,
      status: "success",
    }),
};

export default appToast;
