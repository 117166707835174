import { Role } from "types/roles";
import JwtDecode from "jwt-decode";
import { UserStatus } from "types/user-status";
import { isArray } from "lodash";

const identifiers = {
  role: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
  userName: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
  id: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
  expiryTime: "exp",
  status: "st",
};

const authUtil = {
  isValid: (token: string): boolean => {
    const decoded = decode(token);
    if (!decoded) return false;

    const expiryTime = decoded[identifiers.expiryTime];
    const username = decoded[identifiers.userName];

    var expirationDate = new Date(expiryTime * 1000);

    return new Date() < expirationDate && !!username;
  },
  getRoles: (token: string): Role[] => {
    const decoded = decode(token);
    if (!decoded) return null;

    const roles = decoded[identifiers.role] as Role[] | Role;
    if (isArray(roles)) return roles;
    return [roles];
  },
  getUserName: (token: string): string => {
    const decoded = decode(token);
    if (!decoded) return null;
    const role = decoded[identifiers.userName];
    return role;
  },
  getUserId: (token: string): string => {
    const decoded = decode(token);
    if (!decoded) return null;
    const role = decoded[identifiers.id];
    return role;
  },
  getStatus: (token: string): UserStatus => {
    const decoded = decode(token);
    if (!decoded) return null;

    let value;
    try {
      value = parseInt(decoded[identifiers.status]) as UserStatus;
    } catch (_) {}

    return value;
  },
};

const decode = (token: string): Record<string, any> => {
  if (!token) return null;

  try {
    const decoded = JwtDecode(token) as any;
    if (!decoded) return null;

    return decoded;
  } catch (error) {
    return null;
  }
};

export default authUtil;
