import { lazy } from "react";
import { Route } from "react-router-dom";

const CreateFeedback = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/feedback/create")
);
const EditFeedback = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/feedback/edit")
);
const ViewFeedback = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/feedback/view")
);
const ReviewFeedback = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/feedback/review")
);
const FeedbackList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/feedback")
);
const FeedbackAdminList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/feedback/admin")
);

function FeedbackRoutes(props: FeatureRoute) {
  return [
    <Route path="feedback/create" element={<CreateFeedback />} />,
    <Route path="feedback/edit/:id" element={<EditFeedback />} />,
    <Route path="feedback/view/:id" element={<ViewFeedback />} />,
    props.canReview && (
      <>
        <Route path="feedback/review/:id" element={<ReviewFeedback />} />
        <Route path="feedback/admin" element={<FeedbackAdminList />} />,
      </>
    ),
    <Route path="feedback" element={<FeedbackList />} />,
  ];
}

export default FeedbackRoutes;
