import { lazy } from "react";
import { Route } from "react-router-dom";

const CreateWorkHourModification = lazy(
  () =>
    import(
      /*webpackChunkName: "user" */ "../views/work-hour-modification/create"
    )
);
const EditWorkHourModification = lazy(
  () =>
    import(/*webpackChunkName: "user" */ "../views/work-hour-modification/edit")
);
const ViewWorkHourModification = lazy(
  () =>
    import(/*webpackChunkName: "user" */ "../views/work-hour-modification/view")
);
const ReviewWorkHourModification = lazy(
  () =>
    import(
      /*webpackChunkName: "user" */ "../views/work-hour-modification/review"
    )
);
const WorkHourModification = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/work-hour-modification")
);

const WorkHourModificationAdminList = lazy(
  () =>
    import(
      /*webpackChunkName: "user" */ "../views/work-hour-modification/admin"
    )
);

function WorkHourModificationRoutes(props: FeatureRoute) {
  return [
    // <Route
    //   path="work-hour-modification/create"
    //   element={<CreateWorkHourModification />}
    // />,
    <Route
      path="work-hour-modification/edit/:id"
      element={<EditWorkHourModification />}
    />,
    <Route
      path="work-hour-modification/view/:id"
      element={<ViewWorkHourModification />}
    />,
    props.canReview && (
      <>
        <Route
          path="work-hour-modification/review/:id"
          element={<ReviewWorkHourModification />}
        />
        <Route
          path="work-hour-modification/admin"
          element={<WorkHourModificationAdminList />}
        />
      </>
    ),
    <Route path="work-hour-modification" element={<WorkHourModification />} />,
  ];
}

export default WorkHourModificationRoutes;
