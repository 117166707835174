import axios, { AxiosInstance, ResponseType } from "axios";
import { PaginatedItemsDto } from "types/api";
import { PaginationReq } from "types/pagination";
import { toApiPageOption } from "utils/api-util";

import "./interceptors/request";
import { requestHandler } from "./interceptors/request";
import "./interceptors/response";
import { errorHandler } from "./interceptors/response";

type GetApiOption = {
  query?: object;
  responseType?: ResponseType;
};
type DownloadApiOption = {
  query?: object;
  fileName?: string;
};
type ApiOption = {
  isFormData?: boolean;
};
class HttpsClient {
  _client: AxiosInstance;

  constructor(baseUrl: string) {
    this._client = axios.create({
      baseURL: baseUrl,
    });

    this._client.interceptors.request.use(requestHandler, (err) =>
      Promise.reject(err)
    );

    this._client.interceptors.response.use(
      (response) => response,
      errorHandler
    );
  }

  get<TResponse>(url: string, { query, responseType }: GetApiOption = {}) {
    return this._client
      .get<TResponse>(url, {
        params: query,
        responseType: responseType,
      })
      .then((res) => res.data);
  }

  getPaginated<TResponse>(
    url: string,
    { pageParams, query }: GetApiOption & { pageParams: PaginationReq }
  ) {
    const { page, size } = pageParams;

    return this.get<PaginatedItemsDto<TResponse>>(url, {
      query: { ...query, ...toApiPageOption(page, size) },
    }).then((data) => ({
      items: data.items,
      pagination: {
        currentPage: page,
        size: size,
        total: data.totalCount,
      },
    }));
  }

  post<TBody, TResponse>(
    url: string,
    body: TBody = null,
    option: ApiOption = null
  ) {
    const options = {};

    if (option?.isFormData)
      Object.assign(
        { headers: { "Content-Type": "multipart/form-data" } },
        options
      );

    return this._client
      .post<TResponse>(url, body, options)
      .then((res) => res.data);
  }

  put<TBody, TResponse>(url: string, body: TBody = null) {
    return this._client.put<TResponse>(url, body).then((res) => res.data);
  }

  delete<TResponse>(url: string) {
    return this._client.delete<TResponse>(url).then((res) => res.data);
  }

  download(url: string, { query, fileName }: DownloadApiOption = {}) {
    return this.get(url, {
      responseType: "blob",
      query: query,
    }).then((data) => {
      const href = URL.createObjectURL(data as any);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName ?? `report-${Date.now()}.xls`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }
}

const https = new HttpsClient(process.env.REACT_APP_API_BASE_URL);

export default https;
