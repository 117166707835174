import { Icon } from "@chakra-ui/react";
import { useFormContext } from "@saas-ui/react";
import { RiRestartLine } from "react-icons/ri";
import BaseButton, { BaseButtonProps } from "./BaseButton";

type Props = Omit<BaseButtonProps, "variant"> & {};

const ResetButton = (props: Props) => {
  const form = useFormContext();

  return (
    <BaseButton
      {...props}
      variant="outline"
      size={props?.size ?? "sm"}
      leftIcon={<Icon as={RiRestartLine} />}
      children={props.children ?? "حذف"}
      onClick={(e) => {
        form?.reset();
        if (props.onClick) props.onClick(e);
      }}
    />
  );
};

export default ResetButton;
