import { lazy } from "react";
import { Route } from "react-router-dom";

const Create = lazy(
  () =>
    import(
      /*webpackChunkName: "logistic" */ "../views/schedule-special-date/create"
    )
);
const Edit = lazy(
  () =>
    import(
      /*webpackChunkName: "logistic" */ "../views/schedule-special-date/edit"
    )
);
const List = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/schedule-special-date")
);

function ScheduleRoutes(props: FeatureRoute) {
  return [
    <Route path="schedule-special-date/create" element={<Create />} />,
    <Route path="schedule-special-date/edit/:id" element={<Edit />} />,
    <Route path="schedule-special-date" element={<List />} />,
  ];
}

export default ScheduleRoutes;
