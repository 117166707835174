import { lazy } from "react";
import { Route } from "react-router-dom";
import { Role } from "types/roles";

const CreateMarketingTask = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/create")
);
const EditMarketingTask = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/edit")
);
const ViewMarketingTask = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/view")
);
const ReviewMarketingTask = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/review")
);
const AssignMarketingTask = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/assign")
);
const MarketingTaskList = lazy(
  () => import(/*webpackChunkName: "marketing" */ "../views/marketing-task")
);
const MarketingTaskAdminList = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/admin")
);
const TrackMarketingTask = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-task/track")
);

function MarketingTaskRoutes(props: FeatureRoute) {
  const canAssign =
    props.isAdmin || props.roles.includes(Role.MARKETING_MANAGER);
  const canReview =
    props.isAdmin ||
    props.roles.includes(Role.MARKETING_MANAGER) ||
    props.roles.includes(Role.MANAGER);

  return [
    <Route path="marketing-task/create" element={<CreateMarketingTask />} />,
    <Route path="marketing-task/edit/:id" element={<EditMarketingTask />} />,
    <Route path="marketing-task/view/:id" element={<ViewMarketingTask />} />,
    <Route path="marketing-task/track/:id" element={<TrackMarketingTask />} />,
    canReview && (
      <>
        <Route
          path="marketing-task/review/:id"
          element={<ReviewMarketingTask />}
        />
        <Route
          path="marketing-task/admin"
          element={<MarketingTaskAdminList />}
        />
      </>
    ),
    canAssign && (
      <Route
        path="marketing-task/assign/:id"
        element={<AssignMarketingTask />}
      />
    ),
    <Route path="marketing-task" element={<MarketingTaskList />} />,
  ];
}

export default MarketingTaskRoutes;
