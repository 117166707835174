import { lazy } from "react";
import { Route } from "react-router-dom";
import { Role } from "types/roles";

const ViewMarketingTodo = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-todo/view")
);

const MarketingTaskTodoList = lazy(
  () => import(/*webpackChunkName: "marketing" */ "../views/marketing-todo")
);
const MarketingAdminTaskTodoList = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-todo/admin")
);
const MarketingReviewTaskTodoList = lazy(
  () =>
    import(/*webpackChunkName: "marketing" */ "../views/marketing-todo/review")
);

function MarketingTodoRoutes(props: FeatureRoute) {
  const canReview =
    props.isAdmin ||
    props.roles.includes(Role.MARKETING_MANAGER) ||
    props.roles.includes(Role.MANAGER);

  return [
    <Route path="marketing-todo/view/:id" element={<ViewMarketingTodo />} />,
    <Route path="marketing-todo" element={<MarketingTaskTodoList />} />,
    canReview && (
      <>
        <Route
          path="marketing-todo/review/:id"
          element={<MarketingReviewTaskTodoList />}
        />
        <Route
          path="marketing-todo/admin"
          element={<MarketingAdminTaskTodoList />}
        />
      </>
    ),
  ];
}

export default MarketingTodoRoutes;
