import { useQuery } from "@tanstack/react-query";
import notificationService from "../api/notificationService";
import userQueryKey from "./key";

const useNotificationsNotReadCount = () => {
  return useQuery(
    userQueryKey.useNotificationsNotReadCount(),
    () => notificationService.getNotSeenCount(),
    {
      refetchInterval: 120_000,
    }
  );
};

export default useNotificationsNotReadCount;
