import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

import { RtlProvider } from "providers/RtlProvider";
import { Outlet } from "react-router-dom";
import Illustration from "../components/illustration/Illustration";

function AuthLayout() {
  const authBg = useColorModeValue("white", "navy.900");

  return (
    <RtlProvider>
      <Box>
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" minH="100vh">
            <Illustration>
              <Flex
                maxW={{ base: "100%" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection="column"
              >
                <Outlet />
              </Flex>
            </Illustration>
          </Box>
        </Box>
      </Box>
    </RtlProvider>
  );
}

export default AuthLayout;
