const userQueryKey = {
  getStatus: () => ["user", "getStatus"],
  getInfo: () => ["user", "getInfo"],
  getRemainingTimeOff: () => ["user", "getRemainingTimeOff"],
  getTimeOffSummary: (params: any) => [
    "user",
    "getTimeOffSummary",
    { ...params },
  ],
  getWorkHourModifications: (params?: any) => [
    "user",
    "getWorkHourModifications",
    { ...params },
  ],
  getAdminWorkHourModifications: (params?: any) => [
    "user",
    "getAdminWorkHourModifications",
    { ...params },
  ],
  getWorkHourModificationById: (params?: any) => [
    "user",
    "getWorkHourModificationById",
    { ...params },
  ],
  getWelfareFundById: (params?: any) => [
    "user",
    "getWelfareFundById",
    { ...params },
  ],
  getWorkHourDeductionById: (params?: any) => [
    "user",
    "getWorkHourDeductionById",
    { ...params },
  ],
  getWorkHourAdditionById: (params?: any) => [
    "user",
    "getWorkHourAdditionById",
    { ...params },
  ],
  getWorkHourStatusByUserId: (params?: any) => [
    "user",
    "getWorkHourStatusByUserId",
    { ...params },
  ],
  getTimeOffs: (params?: any) => ["user", "getTimeOffs", { ...params }],
  getWorkHourDeductions: (params?: any) => [
    "user",
    "getWorkHourDeductions",
    { ...params },
  ],
  getWorkHourAdditions: (params?: any) => [
    "user",
    "getWorkHourAdditions",
    { ...params },
  ],
  getWorkHourStatuses: (params?: any) => [
    "user",
    "getWorkHourStatuses",
    { ...params },
  ],
  getMissions: (params?: any) => ["user", "getMissions", { ...params }],
  getFeedbacks: (params?: any) => ["user", "getFeedbacks", { ...params }],
  getNotifications: (params?: any) => [
    "user",
    "getNotifications",
    { ...params },
  ],
  useNotificationsNotReadCount: () => ["user", "useNotificationsNotReadCount"],
  getAdminTimeOffs: (params?: any) => [
    "user",
    "getAdminTimeOffs",
    { ...params },
  ],
  getAdminMissions: (params?: any) => [
    "user",
    "getAdminMissions",
    { ...params },
  ],
  getAdminWelfareFunds: (params?: any) => [
    "user",
    "getAdminWelfareFunds",
    { ...params },
  ],
  getAdminFeedbacks: (params?: any) => [
    "user",
    "getAdminFeedbacks",
    { ...params },
  ],
  getTimeOffById: (params?: any) => ["user", "getTimeOffById", { ...params }],
  getMissionById: (params?: any) => ["user", "getMissionById", { ...params }],
  getIsRegistered: (params?: any) => ["user", "getIsRegistered", { ...params }],
  getFeedbackById: (params?: any) => ["user", "getFeedbackById", { ...params }],
  getNotificationById: (params?: any) => [
    "user",
    "getNotificationById",
    { ...params },
  ],
  getOrganizationUsers: (params?: any) => [
    "user",
    "getOrganizationUsers",
    { ...params },
  ],
  getUserSalary: (params?: any) => ["user", "getUserSalary", { ...params }],
  getOrganizationUserById: (params?: any) => [
    "user",
    "getOrganizationUserById",
    { ...params },
  ],
};

export default userQueryKey;
