import { lazy } from "react";
import { Route } from "react-router-dom";

const OrganizationUserList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/org-users")
);

function OrganizationUserRoutes(props: FeatureRoute) {
  return [<Route path="org-users" element={<OrganizationUserList />} />];
}

export default OrganizationUserRoutes;
