import { lazy } from "react";
import { Route } from "react-router-dom";

const CreateMission = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/mission/create")
);
const EditMission = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/mission/edit")
);
const ViewMission = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/mission/view")
);
const ReviewMission = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/mission/review")
);
const MissionList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/mission")
);
const MissionAdminList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/mission/admin")
);

function MissionRoutes(props: FeatureRoute) {
  return [
    <Route path="mission/create" element={<CreateMission />} />,
    <Route path="mission/edit/:id" element={<EditMission />} />,
    <Route path="mission/view/:id" element={<ViewMission />} />,
    props.canReview && (
      <>
        <Route path="mission/review/:id" element={<ReviewMission />} />
        <Route path="mission/admin" element={<MissionAdminList />} />
      </>
    ),
    <Route path="mission" element={<MissionList />} />,
  ];
}

export default MissionRoutes;
