import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "components/errors/PageNotFound";

const Login = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/login")
);
const SetPassword = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/set-password")
);
const ChangePassword = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/change-password")
);
const ForgotPassword = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/forgot-password")
);

type Props = FeatureRoute & {};
const AuthRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      {props.isLoggedIn && (
        <>
          <Route path="set-password" element={<SetPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </>
      )}
      <Route path={"*"} element={<PageNotFound />} key="404"></Route>
    </Routes>
  );
};

export default AuthRoutes;
