import { create } from "zustand";

interface SidebarState {
  open: boolean;
  toggle: () => void;
}

const tokenKey = "sidebar_open";

export const useSidebar = create<SidebarState>()((set, get) => ({
  open: localStorage.getItem(tokenKey) === "true",
  toggle: () => {
    set((state) => ({ open: !state.open }));
    localStorage.removeItem(tokenKey);
  },
}));
