import https from "api/https";
import {
  MediaDto,
  UserInfoDto,
  UserSalaryFilter,
  UserStatusDto,
} from "types/api";
import { UserRegistrationFormData } from "../types/form";

const userService = {
  getStatus: () => https.get<UserStatusDto>("/user/status"),
  submitInfo: (data: UserRegistrationFormData) =>
    https.post<UserRegistrationFormData, { token?: string }>("/user/info", {
      ...data,
    }),
  updateInfo: (data: UserRegistrationFormData) =>
    https.put<UserRegistrationFormData, { token?: string }>("/user/info", {
      ...data,
    }),
  getInfo: () => https.get<UserInfoDto>("/user/info"),
  getSalary: (data: UserSalaryFilter) =>
    https.get<MediaDto[]>("/user/salary-bills", {
      query: {
        ...data,
      },
    }),
  uploadProfile: ({ file }: { file: string[] | Blob[] }) => {
    var bodyFormData = new FormData();

    bodyFormData.append("file", file[0]);

    return https.post("/user/upload-profile", bodyFormData);
  },
};

export default userService;
