import { lazy } from "react";
import { Route } from "react-router-dom";

const CreateWelfareFund = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/welfare-fund/create")
);
const CancelWelfareFund = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/welfare-fund/cancel")
);
const ReviewWelfareFund = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/welfare-fund/review")
);
const WelfareFundList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/welfare-fund")
);
const WelfareFundAdminList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/welfare-fund/admin")
);

function WelfareFundRoutes(props: FeatureRoute) {
  return [
    <Route path="welfare-fund/create" element={<CreateWelfareFund />} />,
    <Route path="welfare-fund/cancel" element={<CancelWelfareFund />} />,
    props.canReview && (
      <>
        <Route path="welfare-fund/review/:id" element={<ReviewWelfareFund />} />
        <Route path="welfare-fund/admin" element={<WelfareFundAdminList />} />,
      </>
    ),
    <Route path="welfare-fund" element={<WelfareFundList />} />,
  ];
}

export default WelfareFundRoutes;
