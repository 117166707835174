import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "stores/authStore";
import { LoginResponseDto } from "types/api";
import { Role } from "types/roles";
import { UserStatus } from "types/user-status";

const useAuth = () => {
  const store = useAuthStore();
  const navigate = useNavigate();

  function logout() {
    store.logout();
    navigate("/auth/login");
  }

  function login({ token, hasPassword, status }: LoginResponseDto) {
    store.login(token);

    if (hasPassword === false) {
      setTimeout(() => {
        navigate("/auth/set-password");
      }, 0);
      return;
    }

    switch (status.value) {
      case UserStatus.Verified:
        navigate("/");
        return;

      default:
        break;
    }

    navigate("/");
  }

  const isLoggedIn = store.computed.isLoggedIn;
  const verified = store.computed.verified;
  const status = store.computed.status;
  const userName = store.computed.userName;
  const isAdmin = useMemo(
    () => store.computed.isAdmin,
    [store.computed.isAdmin]
  );

  const roles = useMemo(
    () => store.computed.roles ?? [],
    [store.computed.roles]
  );

  const userInfo = useMemo(() => store.userInfo, [store.userInfo]);

  const isManager = useMemo(() => roles.includes(Role.MANAGER), [roles]);
  const isDriver = useMemo(() => roles.includes(Role.DRIVER), [roles]);
  const isInventoryManager = useMemo(
    () => roles.includes(Role.INVENTORY_MANAGER),
    [roles]
  );
  const isLogisticManager = useMemo(
    () => roles.includes(Role.LOGISTIC_MANAGER),
    [roles]
  );
  const isMarketingManager = useMemo(
    () => roles.includes(Role.MARKETING_MANAGER),
    [roles]
  );
  const isMarketingUser = useMemo(
    () => roles.includes(Role.MARKETING_USER),
    [roles]
  );
  const isSecurityUser = useMemo(
    () => roles.includes(Role.SECURITY_USER),
    [roles]
  );

  const canReview = useMemo(
    () =>
      isAdmin ||
      roles.includes(Role.MANAGER) ||
      roles.includes(Role.HUMAN_RESOURCE),
    [roles, isAdmin]
  );

  return {
    canReview,
    isLoggedIn,
    status,
    verified,
    userName,
    login,
    logout,
    roles,
    isAdmin,
    isManager,
    isLogisticManager,
    isDriver,
    isInventoryManager,
    userInfo,
    isMarketingManager,
    isMarketingUser,
    isSecurityUser,
  };
};

export default useAuth;
