import { getMessaging, getToken } from "firebase/messaging";
import appToast from "utils/toast";
import pushNotificationService from "../api/pushNotificationService";
import firebaseApp from "./firebaseSetup";

export const messaging = getMessaging(firebaseApp);

export async function requestNotificationPermission() {
  //requesting permission using Notification API
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    //We can send token to server
    await pushNotificationService.register({ token });
    appToast.success("اعلان‌ها با موفقیت فعال شد");
  } else if (permission === "denied") {
    //notifications are blocked
    appToast.error("دسترسی به اعلان‌ها مسدود شده است");
  }
}
