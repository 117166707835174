import useAuth from "hooks/useAuth";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { UserStatus } from "types/user-status";

type Props = {
  children: ReactNode | any;
};

const WithVerification = ({ children }: Props) => {
  const { isLoggedIn, status } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" replace />;
  }

  switch (status) {
    case UserStatus.Registered:
    case UserStatus.LoggedIn:
      return <Navigate to="/user/registration" replace />;
    case UserStatus.InformationCompleted:
    case UserStatus.NotVerified:
    case UserStatus.InitiallyVerified:
    case UserStatus.Deactivated:
      return <Navigate to="/user/not-verified" replace />;
    case UserStatus.Verified:
      break;

    default:
      return <Navigate to="/user/not-verified" replace />;
  }

  return children;
};

export default WithVerification;
