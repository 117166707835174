import { lazy } from "react";
import { Route } from "react-router-dom";

const WorkHourAddition = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/work-hour-addition")
);

function WorkHourAdditionRoutes(props: FeatureRoute) {
  return [<Route path="work-hour-addition" element={<WorkHourAddition />} />];
}

export default WorkHourAdditionRoutes;
