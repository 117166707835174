import { HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { Persona } from "@saas-ui/react";
import { useIsFetching } from "@tanstack/react-query";

import Brand from "components/brand/Brand";
import NotificationButton from "features/user/components/buttons/NotificationButton";
import userQueryKey from "features/user/queries/key";
import useAuth from "hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useSidebar } from "stores/sidebarStore";

const Navbar = () => {
  const { userName, logout, userInfo, isLoggedIn } = useAuth();
  const isFetchingUserInfo = useIsFetching({
    queryKey: userQueryKey.getStatus(),
    predicate: (query) => query.state.status === "loading",
  });

  const { toggle } = useSidebar();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      as="header"
      borderBottomWidth="1px"
      py="2"
      px="4"
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      gap="2"
    >
      <IconButton
        icon={<HamburgerIcon />}
        aria-label="Menu"
        onClick={toggle}
        ml="2"
        display={["block", "block", "block", "none"]}
      />
      <Brand width="auto" height="48px" center={false} to="/app" />
      <Spacer />
      {isFetchingUserInfo ? (
        <Skeleton fontSize={"sm"} fontWeight="bold">
          Full Name
        </Skeleton>
      ) : userInfo ? (
        <Text fontSize={"sm"} fontWeight="bold">
          {userInfo?.firstName + " " + userInfo?.lastName}
        </Text>
      ) : (
        <></>
      )}
      <IconButton
        aria-label="Toggle Dark Mode"
        size="sm"
        variant={"outline"}
        onClick={toggleColorMode}
        icon={colorMode === "dark" ? <MoonIcon /> : <SunIcon />}
      />
      {isLoggedIn && <NotificationButton />}

      <Menu>
        <MenuButton
          as={IconButton}
          borderRadius="full"
          icon={
            <Persona
              size="sm"
              hideDetails
              name={
                userInfo ? userInfo?.firstName + " " + userInfo?.lastName : null
              }
              src={userInfo?.profilePicture?.absolutePath}
            />
          }
          variant="ghost"
        />
        <MenuList>
          <MenuItem fontSize={"sm"}>{userName}</MenuItem>
          <MenuItem fontSize={"md"} as={NavLink} to="/auth/change-password">
            {"تغییر رمز عبور"}
          </MenuItem>
          <MenuItem fontSize={"md"} onClick={logout}>
            خروج
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Navbar;
