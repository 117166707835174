import { CurrentUserInfoDto, UserStatusDto } from "types/api";
import { Role } from "types/roles";
import { UserStatus } from "types/user-status";
import authUtil from "utils/auth-util";
import { create } from "zustand";

interface AuthState {
  token?: string;
  otpRequestTime?: string;
  userInfo?: CurrentUserInfoDto;
  computed: {
    isLoggedIn: boolean;
    isAdmin: boolean;
    roles: Role[];
    userName: string;
    verified: boolean;
    status: UserStatus;
  };
  login: (token: string, role?: Role) => void;
  logout: () => void;
  otpRequested: () => void;
  setUserInfo: (data?: UserStatusDto) => void;
}

const tokenKey = "token";
const otpRequestTime = "otp";

export const getTokenFromStorage = () => localStorage.getItem(tokenKey);

export const useAuthStore = create<AuthState>()((set, get) => ({
  token: localStorage.getItem(tokenKey),
  otpRequestTime: localStorage.getItem(otpRequestTime),
  userInfo: null,
  computed: {
    get isLoggedIn() {
      return authUtil.isValid(get().token);
    },
    get roles() {
      return authUtil.getRoles(get().token);
    },
    get isAdmin() {
      const roles = get().computed.roles;

      return (
        (roles ?? []).includes(Role.SUPER_ADMIN) ||
        (roles ?? []).includes(Role.HUMAN_RESOURCE)
      );
    },
    get userName() {
      return authUtil.getUserName(get().token);
    },
    get verified() {
      return get().computed.status === UserStatus.Verified;
    },
    get status() {
      // return UserStatus.Verified;
      return authUtil.getStatus(get().token);
    },
  },
  login: (token: string) => {
    set(() => ({ token: token }));
    localStorage.setItem(tokenKey, token);
  },
  logout: () => {
    set({ token: null });
    localStorage.removeItem(tokenKey);
  },
  otpRequested: () => {
    const time = Date.now().toString();

    set({ otpRequestTime: time });
    localStorage.setItem(otpRequestTime, time);
  },
  setUserInfo: (data) => {
    if (data?.token) delete data.token;
    set({ userInfo: data });
  },
}));
