import {
  Box,
  Drawer,
  DrawerContent,
  useBreakpointValue,
} from "@chakra-ui/react";
import { AppShell } from "@saas-ui/react";
import { Outlet } from "react-router-dom";

import { RtlProvider } from "providers/RtlProvider";
import Navbar from "components/layouts/Navbar";
import AppSidebar from "components/layouts/Sidebar";
import { useSidebar } from "stores/sidebarStore";
import { Suspense } from "react";
import Fallback from "components/fallback/Fallback";

function MainLayout(props: { [x: string]: any }) {
  return (
    <RtlProvider>
      <AppShell navbar={<Navbar />} sidebar={<Sidebar />}>
        <Box as="main" flex="1" py="2" px="4" overflowY="auto">
          <Suspense fallback={<Fallback />}>
            <Outlet />
          </Suspense>
        </Box>
      </AppShell>
    </RtlProvider>
  );
}

function Sidebar() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { open, toggle } = useSidebar();

  if (isMobile)
    return (
      <Drawer isOpen={open} placement="right" onClose={toggle}>
        <DrawerContent>
          <AppSidebar isMobile />
        </DrawerContent>
      </Drawer>
    );

  return <AppSidebar />;
}

export default MainLayout;
