import { lazy } from "react";
import { Route } from "react-router-dom";
import { Role } from "types/roles";

const ViewWorkHourStatus = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/work-hour-status/view")
);
const WorkHourStatus = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/work-hour-status")
);

function WorkHourStatusRoutes(props: FeatureRoute) {
  return [
    (props.isAdmin ||
      props.roles.includes(Role.SECURITY_USER) ||
      props.roles.includes(Role.MANAGER)) && (
      <>
        <Route
          path="work-hour-status/view/:id"
          element={<ViewWorkHourStatus />}
        />
        ,
        <Route path="work-hour-status" element={<WorkHourStatus />} />
      </>
    ),
  ];
}

export default WorkHourStatusRoutes;
