import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { NavGroup, NavItem, Sidebar, SidebarSection } from "@saas-ui/react";
import useAccessToWorkflow from "hooks/useAccessToWorkflow";
import useAuth from "hooks/useAuth";
import { useMemo } from "react";
import {
  MdCalendarMonth,
  MdChecklist,
  MdContactMail,
  MdContactPhone,
  MdFeedback,
  MdHomeFilled,
  MdList,
  MdLowPriority,
  MdPedalBike,
  MdPeople,
  MdSchedule,
  MdScheduleSend,
  MdTask,
  MdTaskAlt,
  MdTimer,
  MdTimeToLeave,
} from "react-icons/md";
import { RiOrganizationChart, RiSettings2Fill } from "react-icons/ri";
import {
  TbClockExclamation,
  TbClockPin,
  TbClockPlus,
  TbClockShare,
  TbCurrencyIranianRial,
  TbDoorEnter,
  TbPin,
  TbSpeakerphone,
} from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSidebar } from "stores/sidebarStore";

type Props = {
  isMobile?: boolean;
};

const AppSidebar = ({ isMobile }: Props) => {
  const {
    isAdmin,
    isManager,
    isLogisticManager,
    isMarketingManager,
    isMarketingUser,
    isSecurityUser,
  } = useAuth();

  const { hasAccess } = useAccessToWorkflow();
  const { open: isOpen, toggle } = useSidebar();
  const location = useLocation();

  const canRunTransportation = hasAccess("Transportation");
  const canRequestMarketingTask = hasAccess("MarketingTask");
  const canRequestMission = hasAccess("Mission");
  const canSeeLocation = hasAccess("LocationTrack");

  const menuItems = useMemo(
    () =>
      (
        [
          {
            items: [
              {
                title: "خانه",
                icon: <MdHomeFilled />,
                href: "/",
              },
              (isAdmin || isMarketingUser) && {
                title: "وظایف مارکتینگ",
                href: "/marketing/marketing-todo",
                icon: <MdTaskAlt />,
              },
              {
                title: "وظایف",
                href: "/app/tasks",
                icon: <MdTask />,
              },
              (isAdmin || isSecurityUser || isManager) && {
                title: "لیست ورود/خروج",
                href: "/user/work-hour-status",
                icon: <TbDoorEnter />,
              },
              (isAdmin || canSeeLocation) && {
                title: "نقشه",
                href: "/logistic/location",
                icon: <TbPin />,
              },
            ].filter((q) => q),
          },
          {
            title: "خدمات",
            isCollapsible: true,
            items: [
              canRunTransportation && {
                title: "پیک",
                href: "/logistic/transportation",
                icon: <MdPedalBike />,
              },
              canRequestMarketingTask && {
                title: "درخواست از مارکتینگ",
                href: "/marketing/marketing-task",
                icon: <TbSpeakerphone />,
              },
              {
                title: "اصلاح ورود/خروج",
                href: "/user/work-hour-modification",
                icon: <MdTimer />,
              },
              {
                title: "مرخصی",
                href: "/user/time-off",
                icon: <MdTimeToLeave />,
              },
              canRequestMission && {
                title: "ماموریت",
                href: "/user/mission",
                icon: <TbClockPin />,
              },
              {
                title: "پیشنهادات",
                href: "/user/feedback",
                icon: <MdFeedback />,
              },
            ].filter((q) => q),
          },
          {
            title: "سایر",
            isCollapsible: true,
            items: [
              {
                title: "کاربران سازمان",
                href: "/user/org-users",
                icon: <MdContactMail />,
              },
              {
                title: "لیست مخاطبان",
                href: "/logistic/contact",
                icon: <MdContactPhone />,
              },
              {
                title: "فیش حقوقی",
                href: "/user/salary",
                icon: <TbCurrencyIranianRial />,
              },
            ],
          },
          isAdmin || isManager
            ? {
                title: "مدیریت",
                isCollapsible: true,
                items: [
                  (isAdmin || isLogisticManager) && {
                    title: "مخاطبان عمومی",
                    href: "/logistic/contact-admin",
                    icon: <MdContactPhone />,
                  },
                  isAdmin && {
                    title: "لیست کاربرها",
                    icon: <MdPeople />,
                    href: "/app/users",
                  },
                  isAdmin && {
                    title: "لیست اصلاح ورود/خروج",
                    href: "/user/work-hour-modification/admin",
                    icon: <MdTimer />,
                  },
                  {
                    title: "لیست پیشنهادات",
                    href: "/user/feedback/admin",
                    icon: <MdFeedback />,
                  },
                  {
                    title: "لیست مرخصی",
                    href: "/user/time-off/admin",
                    icon: <MdTimeToLeave />,
                  },
                  {
                    title: "لیست ماموریت",
                    href: "/user/mission/admin",
                    icon: <TbClockPin />,
                  },
                  isAdmin && {
                    title: "لیست کسری ساعت کار",
                    href: "/user/work-hour-deduction",
                    icon: <TbClockExclamation />,
                  },
                  isAdmin && {
                    title: "اضافه کار",
                    href: "/user/work-hour-addition",
                    icon: <TbClockPlus />,
                  },
                ].filter((q) => q),
              }
            : undefined,
          isAdmin || isLogisticManager
            ? {
                title: "پیک",
                isCollapsible: true,
                items: [
                  {
                    title: "درخواست پیک",
                    href: "/logistic/transportation/admin",
                    icon: <MdPedalBike />,
                  },
                  {
                    title: "زمان‌بندی پیک‌ها",
                    href: "/logistic/schedule",
                    icon: <MdSchedule />,
                  },
                  {
                    title: "زمان‌بندی پیک‌ها (روزهای خاص)",
                    href: "/logistic/schedule-special-date",
                    icon: <MdScheduleSend />,
                  },
                ],
              }
            : undefined,
          isAdmin || isMarketingManager
            ? {
                title: "مارکتنیگ",
                isCollapsible: true,
                items: [
                  {
                    title: "لیست درخواست‌ها",
                    href: "/marketing/marketing-task/admin",
                    icon: <MdList />,
                  },
                  {
                    title: "مدیریت تسک‌ها",
                    href: "/marketing/marketing-todo/admin",
                    icon: <MdChecklist />,
                  },
                  {
                    title: "اولویت‌ها",
                    href: "/marketing/marketing-priority",
                    icon: <MdLowPriority />,
                  },
                ],
              }
            : undefined,
          isAdmin
            ? {
                title: "تنظیمات",
                isCollapsible: true,
                items: [
                  {
                    title: "مناسبت‌ها",
                    icon: <MdCalendarMonth />,
                    href: "/calendar/event",
                  },
                  {
                    title: "قوانین ساعت کاری",
                    icon: <TbClockShare />,
                    href: "/app/work-hour-rule",
                  },
                  {
                    title: "دپارتمان ها",
                    icon: <RiOrganizationChart />,
                    href: "/app/departments",
                  },
                  {
                    title: "پیامک",
                    icon: <RiSettings2Fill />,
                    href: "/app/setting/sms",
                  },
                ],
              }
            : undefined,
        ] as SidebarNavGroup[]
      ).filter((q) => q),
    [
      isLogisticManager,
      isAdmin,
      isManager,
      canRunTransportation,
      isMarketingManager,
      canRequestMarketingTask,
      isMarketingUser,
      canRequestMission,
      canSeeLocation,
      isSecurityUser,
    ]
  );

  return (
    <Sidebar
      variant={isMobile ? "default" : isOpen ? "default" : "compact"}
      transition="width"
      transitionDuration="normal"
      width={isMobile ? "280px" : isOpen ? "280px" : "4rem"}
      minWidth="auto"
      overflowY={"auto"}
      _dark={{
        backgroundColor: "whiteAlpha.100",
      }}
      sx={{
        left: "unset !important",
        right: "0 !important",
      }}
    >
      <SidebarSection flexDirection={isOpen ? "row-reverse" : "column"}>
        <IconButton
          onClick={toggle}
          variant="outline"
          size="sm"
          icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          aria-label="Toggle Sidebar"
        />
      </SidebarSection>
      <SidebarSection aria-label="Main">
        {menuItems.map((groupItem, groupIndex) => (
          <NavGroup
            key={"g" + groupIndex}
            title={groupItem.title}
            sx={{
              ".chakra-collapse": {
                overflow: "visible !important",
              },
              ".saas-nav-group__title": {
                display: isOpen ? "block" : "none",
              },
            }}
            isCollapsible={groupItem.isCollapsible}
          >
            {groupItem.items.map((menuItem, index) => (
              <NavItem
                key={index}
                icon={menuItem.icon}
                size="md"
                as={Link}
                to={menuItem.href}
                isActive={location.pathname === menuItem.href}
                onClick={isMobile ? toggle : null}
              >
                {menuItem.title}
              </NavItem>
            ))}
          </NavGroup>
        ))}
      </SidebarSection>
    </Sidebar>
  );
};

export default AppSidebar;
