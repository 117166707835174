import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuProps,
} from "@chakra-ui/react";
import BaseButton, { BaseButtonProps } from "./BaseButton";

type DropdownButtonProps = Omit<MenuProps, "children"> & {
  items: MenuItemProps[];
  buttonProps?: Omit<BaseButtonProps, "as">;
};

const DropdownButton = ({
  items,
  buttonProps,
  ...props
}: DropdownButtonProps) => {
  return (
    <Menu {...props}>
      <MenuButton as={BaseButton} variant="outline" {...buttonProps}>
        بیشتر
      </MenuButton>
      <MenuList>
        {items.map((item) => (
          <MenuItem fontSize={"sm"} {...item} />
        ))}
      </MenuList>
    </Menu>
  );
};

export default DropdownButton;
