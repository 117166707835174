import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "components/errors/PageNotFound";
import ErrorLayout from "layouts/ErrorLayout";

const NotAuthorized = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/not-authorized")
);

type Props = FeatureRoute & {};
const ErrorRoutes = (props: Props) => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="not-authorized" element={<NotAuthorized />} />
        <Route path={"*"} element={<PageNotFound />} key="404"></Route>
      </Route>
    </Routes>
  );
};

export default ErrorRoutes;
