import { Box, IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = IconButtonProps & {
  tooltip?: string;
  badge?: ReactNode;
  to?: string;
};

const IconButtonWithBadge = ({ tooltip, badge, to, ...rest }: Props) => {
  return (
    <Tooltip label={tooltip}>
      <Box pos="relative">
        <IconButton {...rest} as={to ? Link : undefined} to={to} />
        {badge && (
          <Box
            pos="absolute"
            top={"-1"}
            left={"0"}
            bg="red.800"
            color="white"
            borderRadius={"full"}
            padding="2px 6px !important"
            aspectRatio={"1"}
            fontSize={"2xs"}
          >
            {badge}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default IconButtonWithBadge;
