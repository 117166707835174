import { Icon } from "@chakra-ui/react";
import { SubmitButton } from "@saas-ui/react";
import { RiSearch2Line } from "react-icons/ri";
import { BaseButtonProps } from "./BaseButton";

type Props = Omit<BaseButtonProps, "variant"> & {};

const SearchButton = (props: Props) => {
  return (
    <SubmitButton
      {...props}
      size={props?.size ?? "sm"}
      variant="brand"
      leftIcon={<Icon as={RiSearch2Line} />}
      children={props.children ?? "جستجو"}
    />
  );
};

export default SearchButton;
