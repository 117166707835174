import { useQuery } from "@tanstack/react-query";
import useAuth from "hooks/useAuth";
import { useAuthStore } from "stores/authStore";
import userService from "../api/userService";
import userQueryKey from "./key";

const useStatus = () => {
  const { login, setUserInfo } = useAuthStore();
  const { isLoggedIn } = useAuth();

  return useQuery(userQueryKey.getStatus(), userService.getStatus, {
    onSuccess: (data) => {
      if (data.token) {
        login(data.token);
      }
      setUserInfo(data);
    },
    enabled: isLoggedIn,
  });
};

export default useStatus;
