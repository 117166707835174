import { lazy } from "react";
import { Route } from "react-router-dom";

const ReviewWorkHourDeduction = lazy(
  () =>
    import(/*webpackChunkName: "user" */ "../views/work-hour-deduction/review")
);
const WorkHourDeduction = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/work-hour-deduction")
);

function WorkHourDeductionRoutes(props: FeatureRoute) {
  return [
    <Route
      path="work-hour-deduction/review/:id"
      element={<ReviewWorkHourDeduction />}
    />,
    props.isAdmin && (
      <>
        <Route path="work-hour-deduction" element={<WorkHourDeduction />} />
      </>
    ),
  ];
}

export default WorkHourDeductionRoutes;
