import PageNotFound from "components/errors/PageNotFound";
import React from "react";
import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import WithProtection from "utils/auth/with-protection";
import TimeOffRoutes from "./time-off-routes";
import FeedbackRoutes from "./feedback-routes";
import WelfareFundRoutes from "./welfare-fund-routes";
import OrganizationUserRoutes from "./org-users-routes";
import WorkHourModificationRoutes from "./work-hour-modification-routes";
import WithVerification from "utils/auth/with-verification";
import MissionRoutes from "./mission-routes";
import WorkHourDeductionRoutes from "./work-hour-deduction-routes";
import WorkHourStatusRoutes from "./work-hour-status-routes";
import WorkHourAdditionRoutes from "./work-hour-addition-routes";
import SalaryRoutes from "./salary-routes";

const Registration = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/registration")
);
const UserEdit = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/edit")
);
const NotVerified = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/not-verified")
);

const NotificationList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/notification")
);

type Props = FeatureRoute & {};
const UserRoutes = (props: Props) => {
  return (
    <Routes>
      <Route
        element={
          <WithProtection>
            <Outlet />
          </WithProtection>
        }
      >
        <Route path="registration" element={<Registration />} />

        <Route
          element={
            <WithVerification>
              <Outlet />
            </WithVerification>
          }
        >
          {React.Children.toArray(WorkHourModificationRoutes(props))}
          {React.Children.toArray(TimeOffRoutes(props))}
          {React.Children.toArray(FeedbackRoutes(props))}
          {React.Children.toArray(WelfareFundRoutes(props))}
          {React.Children.toArray(OrganizationUserRoutes(props))}
          {React.Children.toArray(MissionRoutes(props))}
          {React.Children.toArray(WorkHourDeductionRoutes(props))}
          {React.Children.toArray(WorkHourStatusRoutes(props))}
          {React.Children.toArray(WorkHourAdditionRoutes(props))}
          {React.Children.toArray(SalaryRoutes(props))}
        </Route>

        <Route path="notification" element={<NotificationList />} />
        <Route path="edit" element={<UserEdit />} />
        <Route path="not-verified" element={<NotVerified />} />
        <Route path={"*"} element={<PageNotFound />} key="404"></Route>
      </Route>
    </Routes>
  );
};

export default UserRoutes;
