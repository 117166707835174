import { Button, Center, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { MdHome, MdRefresh, MdWarning } from "react-icons/md";

const PageNotFound = () => {
  return (
    <Center>
      <VStack>
        <Icon as={MdWarning} h="40" w="40" color="yellow.500" />
        <Text fontSize={"xl"} fontWeight="bold">
          صفحه مورد نظر پیدا نشد
        </Text>
        <HStack pt="8">
          <Button
            variant={"brand"}
            leftIcon={<Icon as={MdRefresh} />}
            onClick={() => window.location.reload()}
          >
            تلاش دوباره
          </Button>
          <Button variant={"setup"} leftIcon={<Icon as={MdHome} />}>
            بازگشت به خانه
          </Button>
        </HStack>
      </VStack>
    </Center>
  );
};

export default PageNotFound;
