export const rtlSassStyles = {
  styles: {
    global: (props: any) => ({
      ".sui-nav-group__content": {
        marignLeft: "unset",
        marginRight: "-0.25rem",
      },
      ".sui-nav-item__icon": {
        marignLeft: "unset",
        marginRight: "-0.25rem",
      },
      '.chakra-toast [aria-label="Close"]': {
        right: "unset",
        left: "1",
      },
      ".chakra-select__wrapper .chakra-select__icon-wrapper": {
        left: "2",
        right: "unset",
      },
      ".chakra-numberinput": {
        direction: "ltr",
      },
      ".sui-select button > span": {
        textAlign: "right",
      },
    }),
  },
};
