import PageNotFound from "components/errors/PageNotFound";
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import WithProtection from "utils/auth/with-protection";
import WithVerification from "utils/auth/with-verification";
import EventRoutes from "./event-routes";

type Props = FeatureRoute & {};
const CalendarRoutes = (props: Props) => {
  return (
    <Routes>
      <Route
        element={
          <WithProtection>
            <Outlet />
          </WithProtection>
        }
      >
        <Route
          element={
            <WithVerification>
              <Outlet />
            </WithVerification>
          }
        >
          {React.Children.toArray(EventRoutes(props))}
        </Route>

        <Route path={"*"} element={<PageNotFound />} key="404"></Route>
      </Route>
    </Routes>
  );
};

export default CalendarRoutes;
