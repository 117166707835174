import { BellIcon } from "@chakra-ui/icons";
import { IconButtonWithBadge } from "components/button";
import useNotificationsNotReadCount from "features/user/queries/useNotificationsNotReadCount";

const NotificationButton = () => {
  const { data } = useNotificationsNotReadCount();

  return (
    <IconButtonWithBadge
      aria-label="Notifications"
      ml="2"
      size="sm"
      variant={"outline"}
      to={"/user/notification"}
      icon={<BellIcon />}
      badge={data > 0 ? data : undefined}
    />
  );
};

export default NotificationButton;
