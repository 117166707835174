import { lazy } from "react";
import { Route } from "react-router-dom";

const Create = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/contact-admin/create")
);
const Edit = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact-admin/edit")
);
const View = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact-admin/view")
);
const List = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact-admin")
);

function ContactAdminRoutes(props: FeatureRoute) {
  return [
    <Route path="contact-admin/create" element={<Create />} />,
    <Route path="contact-admin/edit/:id" element={<Edit />} />,
    <Route path="contact-admin/view/:id" element={<View />} />,
    <Route path="contact-admin" element={<List />} />,
  ];
}

export default ContactAdminRoutes;
