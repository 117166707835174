import { lazy } from "react";
import { Route } from "react-router-dom";

const LocationPath = lazy(
  () => import(/*webpackChunkName: "location" */ "../views/location/path")
);
const List = lazy(
  () => import(/*webpackChunkName: "location" */ "../views/location")
);

function LocationRoutes(props: FeatureRoute) {
  return [
    props.canReview && (
      <>
        <Route path="location/path/:id" element={<LocationPath />} />,
        <Route path="location" element={<List />} />,
      </>
    ),
  ];
}

export default LocationRoutes;
