import { DeleteIcon } from "@chakra-ui/icons";
import { useColorModeValue } from "@chakra-ui/system";
import { useArrayFieldRemoveButton } from "@saas-ui/react";
import { BaseButtonProps } from "./BaseButton";
import OutlineButton from "./OutlineButton";

type Props = BaseButtonProps & {
  label?: string;
};
export const ArrayFieldRemoveButton: React.FC<Props> = (props) => {
  const color = useColorModeValue("red", "red");
  return (
    <OutlineButton
      aria-label="حذف سطر"
      {...useArrayFieldRemoveButton()}
      {...props}
      colorScheme={color}
    >
      <DeleteIcon />
    </OutlineButton>
  );
};

export default ArrayFieldRemoveButton;
