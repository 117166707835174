export enum Role {
  SUPER_ADMIN = "super_admin",
  HUMAN_RESOURCE = "hr",
  MANAGER = "mng",
  LOGISTIC_MANAGER = "lg_mng",
  DRIVER = "drv",
  INVENTORY_MANAGER = "inv_mng",
  MARKETING_MANAGER = "mkt_mng",
  MARKETING_USER = "mkt_usr",
  SECURITY_USER = "sct_usr",
}
