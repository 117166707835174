import { SpinnerIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";

const Fallback = () => {
  return (
    <Box
      display={"flex"}
      w="100vw"
      h="100vh"
      alignItems={"center"}
      justifyContent="center"
    >
      <SpinnerIcon />
    </Box>
  );
};

export default Fallback;
