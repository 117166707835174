import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
  content: {
    _dark: {
      backgroundColor: "navy.800",
    },
  },
});
const popoverTheme = defineMultiStyleConfig({ baseStyle });

export const popoverStyles = {
  components: {
    Popover: popoverTheme,
  },
};
