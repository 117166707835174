/* eslint-disable */

import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

function Footer() {
  let textColor = useColorModeValue("gray.400", "white");
  let linkColor = useColorModeValue({ base: "gray.400", lg: "white" }, "white");
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px={{ base: "30px", md: "0px" }}
      bottom="30px"
      left={{ base: 0, lg: "unset" }}
      right={{ base: "unset", lg: 40 }}
      position={"fixed"}
      fontSize="sm"
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", lg: "0px" }}
      >
        <Text as="span" fontWeight="500" ms="4px">
          کلیه حقوق برای{" "}
          <Link
            mx="3px"
            color={textColor}
            href="https://homatelecom.com"
            target="_blank"
            fontWeight="700"
          >
            هما تلکام
          </Link>{" "}
          محفوظ است &copy; {new Date().getFullYear()}
        </Text>
      </Text>
    </Flex>
  );
}

export default Footer;
