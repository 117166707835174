import { mode } from "@chakra-ui/theme-tools";
export const dateInputStyles = {
  styles: {
    global: (props: any) => ({
      ".date-input": {
        backgroundColor: mode("white", "navy.900 !important")(props),
        borderRadius: "md !important",
        fontSize: "sm",
        width: "265px !important",
        height: "302px !important",
        zIndex: "popover",
        ".item": {
          width: "265px",
        },

        ".zm-DaysButton": {
          color: mode("navy.800", "navy.50")(props),

          '&[data-value^="Fri"]': {
            color: "red !important",
          },
        },

        ".zm-Header": {
          height: "40px",
          backgroundColor: mode("navy.200", "navy.800")(props),

          button: {
            color: mode("navy.800", "navy.100 ")(props),
            "&:hover": {
              backgroundColor: mode("navy.300", "navy.600")(props),
            },
          },
        },
        ".zm-YearPickerButton": {
          width: "4rem",
        },
        ".zm-MonthPickerButton": {
          width: "4rem",
        },
        "div:has(> .zm-YearPickerButton)": {
          gridTemplateColumns: "repeat(3, 70px)",
          maxHeight: "302px",
        },
        "div:has(> .zm-MonthPickerButton)": {
          height: "260px",
          overflow: "auto",
        },
        "> div:nth-child(2)": {
          "div": {
            width: "32px",
          },
        },
        "div[role='row'] button": {
          width: "32px",
          height: "32px",
        },
      },
      ".date-input__input": {
        width: "100%",

        outline: "2px solid transparent",
        outlineOffset: "2px",

        fontWeight: "400",
        borderRadius: "md",
        color: mode("secondaryGray.900", "whiteAlpha.900")(props),
        fontSize: "md",
        paddingInlineStart: "3",
        paddingInlineEnd: "3",
        height: "9",
        border: "1px solid",
        borderColor: mode("blackAlpha.300", "whiteAlpha.300")(props),
        background: "inherit",

        "&[disabled]": {
          opacity: 0.4,
          cursor: "not-allowed",
        },
        _placeholder: {
          color: mode("secondaryGray.600", "secondaryGray.700")(props),
        },
      },
    }),
  },
};
