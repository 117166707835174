import https from "api/https";
import { UserNotificationListDto, UserNotificationDto } from "types/api";
import { PaginationReq } from "types/pagination";

const notificationService = {
  getAll: (props: PaginationReq & {}) =>
    https.getPaginated<UserNotificationListDto>("/notification", {
      pageParams: {
        page: props.page,
        size: props.size,
      },
    }),
  getById: ({ id }: { id: string }) =>
    https.get<UserNotificationDto>(`/notification/${id}`),
  read: ({ id }: { id: string }) => https.post(`/notification/${id}/read`),
  getNotSeenCount: () => https.get<number>(`/notification/not-seen-count`),
  delete: ({ id }: { id: string }) => https.delete(`/notification/${id}`),
};

export default notificationService;
