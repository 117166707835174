import { lazy } from "react";
import { Route } from "react-router-dom";

const Create = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact/create")
);
const Edit = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact/edit")
);
const View = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact/view")
);
const List = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/contact")
);

function ContactRoutes(props: FeatureRoute) {
  return [
    <Route path="contact/create" element={<Create />} />,
    <Route path="contact/edit/:id" element={<Edit />} />,
    <Route path="contact/view/:id" element={<View />} />,
    <Route path="contact" element={<List />} />,
  ];
}

export default ContactRoutes;
