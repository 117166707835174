import { Icon } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import BaseButton, { BaseButtonProps } from "./BaseButton";

type Props = Omit<BaseButtonProps, "variant"> & {};

const AddButton = (props: Props) => {
  return (
    <BaseButton
      {...props}
      variant="brand"
      leftIcon={<Icon as={MdAdd} />}
      children={props.children ?? "جدید"}
    />
  );
};

export default AddButton;
