import { lazy } from "react";
import { Route } from "react-router-dom";

const CreateEvent = lazy(
  () => import(/*webpackChunkName: "marketing" */ "../views/event/create")
);
const EditEvent = lazy(
  () => import(/*webpackChunkName: "marketing" */ "../views/event/edit")
);
const EventList = lazy(
  () => import(/*webpackChunkName: "marketing" */ "../views/event")
);

function EventRoutes(props: FeatureRoute) {
  if (!props.isAdmin) return [];

  return [
    <Route path="event/create" element={<CreateEvent />} />,
    <Route path="event/edit/:id" element={<EditEvent />} />,
    <Route path="event" element={<EventList />} />,
  ];
}

export default EventRoutes;
