import { lazy } from "react";
import { Route } from "react-router-dom";
import { Role } from "types/roles";

const CreateMarketingPriority = lazy(
  () =>
    import(
      /*webpackChunkName: "marketing" */ "../views/marketing-priority/create"
    )
);
const EditMarketingPriority = lazy(
  () =>
    import(
      /*webpackChunkName: "marketing" */ "../views/marketing-priority/edit"
    )
);
const MarketingPriorityList = lazy(
  () => import(/*webpackChunkName: "marketing" */ "../views/marketing-priority")
);

function MarketingPriorityRoutes(props: FeatureRoute) {
  if (
    !props.roles.includes(Role.MARKETING_MANAGER) &&
    !props.roles.includes(Role.SUPER_ADMIN)
  )
    return [];

  return [
    <Route
      path="marketing-priority/create"
      element={<CreateMarketingPriority />}
    />,
    <Route
      path="marketing-priority/edit/:id"
      element={<EditMarketingPriority />}
    />,
    <Route path="marketing-priority" element={<MarketingPriorityList />} />,
  ];
}

export default MarketingPriorityRoutes;
