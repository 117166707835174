import useStatus from "features/user/queries/useStatus";
import { useMemo } from "react";

const useAccessToWorkflow = () => {
  const { data: status, isLoading: loading } = useStatus();
  const workflows = useMemo(() => status?.workflows ?? [], [status]);

  function hasAccess(
    type:
      | "ReviewUserInfo"
      | "WorkHourModification"
      | "TimeOff"
      | "UserFeedback"
      | "Transportation"
      | "WelfareFund"
      | "MarketingTask"
      | "Mission"
      | "LocationTrack"
  ) {
    let value = 1;
    switch (type) {
      case "ReviewUserInfo":
        value = 1;
        break;
      case "WorkHourModification":
        value = 2;
        break;
      case "TimeOff":
        value = 3;
        break;
      case "UserFeedback":
        value = 4;
        break;
      case "Transportation":
        value = 5;
        break;
      case "WelfareFund":
        value = 6;
        break;
      case "MarketingTask":
        value = 7;
        break;
      case "Mission":
        value = 8;
        break;
      case "LocationTrack":
        value = 11;
        break;

      default:
        break;
    }
    return (workflows ?? []).find((q) => q.value === value)?.canRun ?? false;
  }

  return { hasAccess, loading };
};

export default useAccessToWorkflow;
