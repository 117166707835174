import { forwardRef } from "react";
import { Icon } from "@chakra-ui/react";
import { MdSave } from "react-icons/md";
import BaseButton, { BaseButtonProps } from "./BaseButton";

type Props = Omit<BaseButtonProps, "variant"> & {};

const ExportButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (props: Props, ref) => {
    return (
      <BaseButton
        {...props}
        variant="outline"
        leftIcon={<Icon as={MdSave} />}
        children={props.children ?? "فایل اکسل"}
        ref={ref}
      />
    );
  }
);

export default ExportButton;
