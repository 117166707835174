import { Box } from "@chakra-ui/react";
import { AppShell } from "@saas-ui/react";
import { Outlet } from "react-router-dom";

import { RtlProvider } from "providers/RtlProvider";

function ErrorLayout(props: { [x: string]: any }) {
  return (
    <RtlProvider>
      <AppShell>
        <Box as="main" flex="1" py="2" px="4" overflowY="auto">
          <Outlet />
        </Box>
      </AppShell>
    </RtlProvider>
  );
}

export default ErrorLayout;
