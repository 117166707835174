import PageNotFound from "components/errors/PageNotFound";
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import WithProtection from "utils/auth/with-protection";
import WithVerification from "utils/auth/with-verification";

import ContactRoutes from "./contact-routes";
import ContactAdminRoutes from "./contact-admin-routes";
import TransportationRoutes from "./transportation-routes";
import ScheduleRoutes from "./schedule-routes";
import ScheduleSpecialDateRoutes from "./schedule-special-date-routes";
import LocationRoutes from "./location-routes";

type Props = FeatureRoute & {};

const UserRoutes = (props: Props) => {
  return (
    <Routes>
      <Route
        element={
          <WithProtection>
            <Outlet />
          </WithProtection>
        }
      >
        <Route
          element={
            <WithVerification>
              <Outlet />
            </WithVerification>
          }
        >
          {React.Children.toArray(ContactRoutes(props))}
          {React.Children.toArray(ContactAdminRoutes(props))}
          {React.Children.toArray(TransportationRoutes(props))}
          {React.Children.toArray(ScheduleRoutes(props))}
          {React.Children.toArray(ScheduleSpecialDateRoutes(props))}
          {React.Children.toArray(LocationRoutes(props))}
        </Route>

        <Route path={"*"} element={<PageNotFound />} key="404"></Route>
      </Route>
    </Routes>
  );
};

export default UserRoutes;
