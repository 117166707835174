import { useAuthStore } from "stores/authStore";
import appToast from "utils/toast";

const forbiddenExceptionCodes = ["UserNotFound", "UserIsDisable"];
const unauthorizedCodes = [401];

export function errorHandler(error: any) {
  let message = "",
    extraDescription = null,
    errorName = "";

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const code = error.response.data?.error?.code;
    message = error.response.data?.error?.message;

    if (forbiddenExceptionCodes.includes(code)) {
      const logout = useAuthStore.getState().logout;

      logout();
    }

    if (unauthorizedCodes.includes(error.response.status)) {
      message = "خطا در اعتبار سنجی";
      extraDescription = "لطفا دوباره وارد اکانت خود شوید";
      errorName = "UnAuthorized";
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    message = "خطا در برقراری ارتباط با شبکه";
    errorName = error.name;
  } else {
    // Something happened in setting up the request that triggered an Error
    message = error.message;
    errorName = error?.name;
  }

  toastError(message, extraDescription, errorName);

  return Promise.reject(error);
}

function toastError(
  title: string,
  message: string = "خطا",
  errorName?: string
) {
  appToast.error(message, title, { id: errorName });
}
