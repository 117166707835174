import { lazy } from "react";
import { Route } from "react-router-dom";
import { Role } from "types/roles";

const Create = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/transportation/create")
);
const Edit = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/transportation/edit")
);
const EditAdmin = lazy(
  () =>
    import(
      /*webpackChunkName: "logistic" */ "../views/transportation/edit-admin"
    )
);
const View = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/transportation/view")
);
const Review = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/transportation/review")
);
const List = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/transportation")
);
const AdminList = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/transportation/admin")
);
const ViewDriverTransportation = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/transportation/driver")
);
const TrackTransportation = lazy(
  () =>
    import(/*webpackChunkName: "logistic" */ "../views/transportation/track")
);

function TransportationRoutes(props: FeatureRoute) {
  return [
    <Route path="transportation/create" element={<Create />} />,
    <Route path="transportation/edit/:id" element={<Edit />} />,
    <Route path="transportation/edit-admin/:id" element={<EditAdmin />} />,
    <Route path="transportation/view/:id" element={<View />} />,
    (props.canReview || props.roles.includes(Role.INVENTORY_MANAGER)) && (
      <>
        <Route path="transportation/review/:id" element={<Review />} />
      </>
    ),
    props.canReview && (
      <>
        <Route path="transportation/admin" element={<AdminList />} />
      </>
    ),
    <Route
      path="transportation/driver/:id"
      element={<ViewDriverTransportation />}
    />,
    <Route path="transportation/track/:id" element={<TrackTransportation />} />,
    <Route path="transportation" element={<List />} />,
  ];
}

export default TransportationRoutes;
