import { extendTheme } from "@chakra-ui/react";
import { theme as baseTheme } from "@saas-ui/react";

import { CardComponent } from "./additions/card/card";
import { buttonStyles } from "./components/button";
import { badgeStyles } from "./components/badge";
import { inputStyles } from "./components/input";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { linkStyles } from "./components/link";
import { popoverStyles } from "./components/popover";
import { tagStyles } from "./components/tag";
import { breakpoints } from "./foundations/breakpoints";
import { globalStyles } from "./styles";
import { rtlSassStyles } from "./rtl/rtl-sass-styles";
import { dateInputStyles } from "./components/dateInput";

export default extendTheme(
  {
    breakpoints,
    fonts: {
      heading: `"Vazirmatn UI", "calibri", tahoma, sans-serif`,
      body: `"Vazirmatn UI", "calibri", tahoma, sans-serif`,
    },
  }, // Breakpoints
  globalStyles,
  badgeStyles, // badge styles
  buttonStyles, // button styles
  linkStyles, // link styles
  progressStyles, // progress styles
  sliderStyles, // slider styles
  inputStyles, // input styles
  textareaStyles, // textarea styles
  switchStyles, // switch styles
  popoverStyles, // popover component
  CardComponent, // card component
  tagStyles, // tag component
  rtlSassStyles,
  dateInputStyles,
  baseTheme
);
