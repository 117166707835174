import { ReactNode } from "react";
// Add this somewhere in the root of your project.
import { Form } from "@saas-ui/react";
import { yupResolver } from "@saas-ui/forms/yup";
import { AnyObjectSchema } from "yup";

// @ts-ignore
Form.getResolver = (schema: AnyObjectSchema) => yupResolver(schema);

type Props = {
  children: ReactNode;
};

const FormProvider = ({ children }: Props) => {
  return <>{children}</>;
};

export default FormProvider;
