import { forwardRef } from "react";
import BaseButton, { BaseButtonProps } from "./BaseButton";

const LinkButton = forwardRef<
  HTMLButtonElement,
  Omit<BaseButtonProps, "variant">
>((props, ref) => {
  return (
    <BaseButton {...props} variant="link" colorScheme={"blue"} ref={ref} />
  );
});

export default LinkButton;
