import useAuth from "hooks/useAuth";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode | any;
};

const WithProtection = ({ children }: Props) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/error/not-authorized" replace />;
  }

  return children;
};

export default WithProtection;
