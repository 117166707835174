import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import PageNotFound from "components/errors/PageNotFound";
import WithProtection from "utils/auth/with-protection";

const DisciplinaryAgreement = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/disciplinary-agreement")
);
const NdaAgreement = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/nda-agreement")
);
const RecruitmentPermit = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/recruitment-permit")
);
const WorkContract = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/work-contract")
);
const PersonalInformation = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/personal-information")
);
const InvitationLetter = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/invitation-letter")
);
const AllDocs = lazy(
  () => import(/*webpackChunkName: "auth" */ "../views/all-docs")
);

type Props = FeatureRoute & {};

const DocumentRoutes = (props: Props) => {
  return (
    <Routes>
      <Route
        element={
          <WithProtection>
            <Outlet />
          </WithProtection>
        }
      >
        <Route path="personal-info/:userId" element={<PersonalInformation />} />
        <Route path="all-docs/:userId" element={<AllDocs />} />
        <Route
          path="disciplinary-agreement/:userId"
          element={<DisciplinaryAgreement />}
        />
        <Route path="nda-agreement/:userId" element={<NdaAgreement />} />
        <Route
          path="recruitment-permit/:userId"
          element={<RecruitmentPermit />}
        />
        <Route path="work-contract/:userId" element={<WorkContract />} />
        <Route
          path="invitation-letter/:id/:sourceId"
          element={<InvitationLetter />}
        />
        <Route path={"*"} element={<PageNotFound />} key="404"></Route>
      </Route>
    </Routes>
  );
};

export default DocumentRoutes;
