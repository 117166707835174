import { lazy } from "react";
import { Route } from "react-router-dom";

const CreateTimeOff = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/time-off/create")
);
const EditTimeOff = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/time-off/edit")
);
const ViewTimeOff = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/time-off/view")
);
const ReviewTimeOff = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/time-off/review")
);
const TimeOffList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/time-off")
);
const TimeOffAdminList = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/time-off/admin")
);

function TimeOffRoutes(props: FeatureRoute) {
  return [
    <Route path="time-off/create" element={<CreateTimeOff />} />,
    <Route path="time-off/edit/:id" element={<EditTimeOff />} />,
    <Route path="time-off/view/:id" element={<ViewTimeOff />} />,
    props.canReview && (
      <>
        <Route path="time-off/review/:id" element={<ReviewTimeOff />} />
        <Route path="time-off/admin" element={<TimeOffAdminList />} />
      </>
    ),
    <Route path="time-off" element={<TimeOffList />} />,
  ];
}

export default TimeOffRoutes;
