import { Navigate } from "react-router-dom";

import MainLayout from "layouts/MainLayout";
import AuthLayout from "features/auth/layouts";

import HomeRoutes from "features/home/routes";
import AuthRoutes from "features/auth/routes";
import UserRoutes from "features/user/routes";
import LogisticRoutes from "features/logistic/routes";
import MarketingRoutes from "features/marketing/routes";
import DocumentLayout from "features/documents/layouts";
import DocumentRoutes from "features/documents/routes";
import ErrorRoutes from "features/errors/routes";
import CalendarRoutes from "features/calendar/routes";

const App = () => {
  return <MainLayout />;
};

type Props = FeatureRoute & {};

export const allRoutes = (props: Props) => [
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/", element: <Navigate to="/app/" replace /> },
      {
        path: "/app/*",
        element: <HomeRoutes {...props} />,
      },
      {
        path: "/user/*",
        element: <UserRoutes {...props} />,
      },
      {
        path: "/logistic/*",
        element: <LogisticRoutes {...props} />,
      },
      {
        path: "/marketing/*",
        element: <MarketingRoutes {...props} />,
      },
      {
        path: "/calendar/*",
        element: <CalendarRoutes {...props} />,
      },
    ],
  },
  {
    path: "/documents/*",
    element: <DocumentLayout />,
    children: [
      {
        path: "*",
        element: <DocumentRoutes {...props} />,
      },
    ],
  },
  {
    path: "/auth/*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <AuthRoutes {...props} />,
      },
    ],
  },
  {
    path: "/error/*",
    element: <ErrorRoutes {...props} />,
  },
];
