import { lazy } from "react";
import { Route } from "react-router-dom";

const Create = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/schedule/create")
);
const Edit = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/schedule/edit")
);
const List = lazy(
  () => import(/*webpackChunkName: "logistic" */ "../views/schedule")
);

function ScheduleRoutes(props: FeatureRoute) {
  return [
    <Route path="schedule/create" element={<Create />} />,
    <Route path="schedule/edit/:id" element={<Edit />} />,
    <Route path="schedule" element={<List />} />,
  ];
}

export default ScheduleRoutes;
