import PageNotFound from "components/errors/PageNotFound";
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import WithProtection from "utils/auth/with-protection";
import WithVerification from "utils/auth/with-verification";

import MarketingTaskRoutes from "./marketing-task-routes";
import MarketingPriorityRoutes from "./marketing-priority-routes";
import MarketingTodoRoutes from "./marketing-todo-routes";

type Props = FeatureRoute & {};

const UserRoutes = (props: Props) => {
  return (
    <Routes>
      <Route
        element={
          <WithProtection>
            <Outlet />
          </WithProtection>
        }
      >
        <Route
          element={
            <WithVerification>
              <Outlet />
            </WithVerification>
          }
        >
          {React.Children.toArray(MarketingTaskRoutes(props))}
          {React.Children.toArray(MarketingPriorityRoutes(props))}
          {React.Children.toArray(MarketingTodoRoutes(props))}
        </Route>

        <Route path={"*"} element={<PageNotFound />} key="404"></Route>
      </Route>
    </Routes>
  );
};

export default UserRoutes;
