import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    p: "1",
  },
});

const tagTheme = defineMultiStyleConfig({ baseStyle });

export const tagStyles = {
  components: {
    Tag: tagTheme,
  },
};
