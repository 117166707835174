import { lazy } from "react";
import { Route } from "react-router-dom";

const UserSalary = lazy(
  () => import(/*webpackChunkName: "user" */ "../views/salary")
);

function SalaryRoutes(props: FeatureRoute) {
  return [<Route path="salary" element={<UserSalary />} />];
}

export default SalaryRoutes;
