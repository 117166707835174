import { Button, ButtonProps } from "@chakra-ui/react";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

export type BaseButtonProps = ButtonProps & {
  to?: string;
};
const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (props, ref) => {
    return (
      <Button
        {...props}
        as={props.to ? Link : undefined}
        to={props.to}
        ref={ref}
      />
    );
  }
);

export default BaseButton;
