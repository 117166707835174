import { useEffect } from "react";
import { onMessage } from "firebase/messaging";
import { useToast } from "@chakra-ui/react";
import { messaging } from "../lib/firebaseMessage";

const usePushNotification = () => {
  const toast = useToast();

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      toast({
        id: payload.messageId,
        position: "top-left",
        title: payload.notification.title,
        description: payload.notification.body,
        variant: "solid",
        duration: null,
        isClosable: true,
      });
    });

    return () => {
      unsubscribe();
    };
  }, [toast]);
};

export default usePushNotification;
