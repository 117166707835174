import { Box, useColorModeValue } from "@chakra-ui/react";

import { RtlProvider } from "providers/RtlProvider";
import { Outlet } from "react-router-dom";

function DocumentLayout() {
  const authBg = useColorModeValue("white", "navy.900");

  return (
    <RtlProvider>
      <Box>
        <Outlet />
      </Box>
    </RtlProvider>
  );
}

export default DocumentLayout;
